import React, { Component } from "react";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState } from "draft-js";
import { API_URL } from "../config/app-config";
import axios from "axios";
import AuthService from "../session/AuthService_old";
import i18n from "../i18n";
import { withTranslation } from "react-i18next";
import draftToHtml from "draftjs-to-html";
const MAX_LENGTH = 3999;

function uploadImageCallBack(file) {
  console.group("Article : Started");
  console.log("File name :", file);

  const uploadFile = async (data) => {
    try {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const response = await axios.post(
        `${API_URL}auth/point-to-image-url`,
        data,
        config
      );
      return response.data.data.articleUrl ?? null;
    } catch (err) {
      console.log("Error Uploading File :", err);
    }
  };

  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.onloadend = async () => {
      const imagedata = new FormData();
      imagedata.append("articleFile", file);
      const response = await uploadFile(imagedata);
      console.log("Article Response", response);
      resolve({ data: { link: response } });
    };
    reader.readAsDataURL(file);
  });
}

class TextEditorHomePost extends Component {
  state = {
    editorState: EditorState.createEmpty(),
    currentValues: null,
    friendList: [],
  };

  componentDidMount() {
    this.addFriendList();
  }
  addFriendList = () => {
    const filterdList = this.props.friendslist.map((data) => ({
      text: data.userName,
      value: data.userName,
      url: "friendDescription/" + data.userId,
    }));
    console.log("friendslist", filterdList);
    this.setState({
      friendList: filterdList,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.friendslist !== prevProps.friendslist) {
      this.addFriendList();
    }
    if (this.props.editorValue !== prevProps.editorValue) {
      let langValue = AuthService.getCommonLanguages();
      i18n.changeLanguage(langValue);
      console.log("questionDescribed props 2", this.props.editorValue);
      const blocksFromHtml = htmlToDraft(this.props.editorValue);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      console.log(contentState);
      console.log(EditorState.createWithContent(contentState));
      this.setState({
        editorState: EditorState.createWithContent(contentState),
      });
    }
  }
  handleChange = (event) => {
    const reset = { ...event.blocks[0], text: null };
    this.setState({
      currentValues: event,
    });
  };
  onClear = () => {
    this.setState({
      editorState: EditorState.createEmpty(),
    });
  };
  render() {
    const { t } = this.props;
    const { editorState, currentValues } = this.state;
    // console.log("questionDescribed props render", this.props.editorValue);
    if (this.props.textBoxStatus) {
      this.addUpdateData();
    }
    return (
      <div>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          // editorStyle={{ border: "1px solid gray" }}
          editorClassName="demo-editor"
          placeholder={t("What do you want to share")}
          onEditorStateChange={this.onEditorStateChange}
          onChange={this.handleChange}
          handleBeforeInput={this._handleBeforeInput}
          handlePastedText={this._handlePastedText}
          hashtag={{
            separator: " ",
            trigger: "#",
          }}
          mention={{
            separator: " ",
            trigger: "@",
            suggestions: this.state.friendList,

            // suggestions: [
            //   { text: "JavaScript", value: "javascript", url: "js" },
            //   { text: "Golang", value: "golang", url: "go" },
            // ],
          }}
          toolbar={{
            // options: [
            //   "inline",
            //   "link",
            //   "list",
            //   "textAlign",
            //   "colorPicker",
            //   "emoji",
            //   "history",
            // ],
            textAlign: { inDropdown: true, className: "hide" },
            remove: { className: "hide" },
            history: { className: "hide" },

            inline: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ["bold", "italic", "underline"],
            },

            list: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: [],
            },

            blockType: {
              inDropdown: false,
              options: [],
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
            },

            link: {
              inDropdown: this.props.isArticle ? true : false,
              // inDropdown:false,
              // className: this.props.isArticle ? "show" : "hide",
              // className:undefined,
              component: undefined,
              popupClassName: undefined,
              dropdownClassName: undefined,
              showOpenOptionOnHover: true,
              defaultTargetOption: "_self",
              options: ["link"],
              // options: [],
              // inDropdown:true,
              // className:"show"
            },

            colorPicker: {
              className: "hide",
            },
            emoji: {
              className: "show",
            },
            image: {
              className: this.props.isArticle ? "show" : "hide",
              // uploadCallback: uploadImageCallBack, alt: { present: true, mandatory: true }
              uploadCallback: uploadImageCallBack,
            },
            // blockType: {
            //   className: "hide",
            // },
            fontSize: {
              className: "hide",
            },
            fontFamily: {
              className: "hide",
            },
            // history: {
            //   className: "hide",
            // },
            // remove: {
            //   className: "hide",
            // },
            embedded: {
              className: "hide",
            },
          }}
        />
      </div>
    );
  }
  // onEditorStateChange = (editorState) => {
  //   const content = editorState.getCurrentContent();
  //   const rawContent = convertToRaw(content);
  //   console.log("outer-rawContent", rawContent);

  //   // Loop through blocks to clean up mentions with trailing "l"
  //   rawContent.blocks = rawContent.blocks.map((block) => {
  //     let blockText = block.text;
  //     const entityRanges = block.entityRanges;
  //     console.log("1-entityRanges", entityRanges);
  //     // Adjust text for trailing "l" in mentions
  //     entityRanges.forEach((range) => {
  //       const mentionText = blockText.substring(
  //         range.offset,
  //         range.offset + range.length
  //       );
  //       console.log("2-mentionText", mentionText);
  //       if (mentionText.endsWith("l")) {
  //         console.log("3-mentionText ends with l");

  //         const correctedMention = mentionText.slice(0, -2); // Remove the trailing "l"
  //         console.log(
  //           "4-mentionText ends with l correctedMention",
  //           correctedMention
  //         );

  //         blockText =
  //           blockText.substring(0, range.offset) +
  //           correctedMention +
  //           blockText.substring(range.offset + range.length);
  //       }
  //       console.log("5-blockText", blockText);
  //     });

  //     return { ...block, text: blockText };
  //   });

  //   // Cleaned content to HTML
  //   const cleanedContentHTML = draftToHtml(rawContent);

  //   // Update state and pass cleaned HTML to parent
  //   this.setState({ editorState });
  //   // this.props.editorStateValue(cleanedContentHTML);
  // };

  // onEditorStateChange = (editorState) => {
  //   this.setState({
  //     editorState,
  //   });
  //   this.props.editorStateValue(
  //     draftToHtml(convertToRaw(editorState.getCurrentContent()))
  //   );
  // };
  onEditorStateChange = (editorState) => {
    const content = editorState.getCurrentContent();
    const selection = editorState.getSelection(); // Save the current selection
    const rawContent = convertToRaw(content);

    let updatedSelection = selection;

    // Get the current block where the cursor is located
    const blockKey = selection.getStartKey();
    const currentBlock = content.getBlockForKey(blockKey);
    const blockText = currentBlock.getText();

    // Get the cursor position in the current block
    const cursorOffset = selection.getStartOffset();

    // Extract the text before the cursor and determine the current word
    const textBeforeCursor = blockText.substring(0, cursorOffset);
    const words = textBeforeCursor.split(" ");
    const currentWord = words[words.length - 1]; // Last word is the currently typed word

    console.log("Current word being typed:", currentWord);

    const cleanedBlocks = rawContent.blocks.map((block) => {
      let blockText = block.text;
      const entityRanges = block.entityRanges;

      entityRanges.forEach((range) => {
        const mentionText = blockText.substring(
          range.offset,
          range.offset + range.length
        );

        if (mentionText.toLowerCase().startsWith("@all")) {
          // Remove trailing "l" (with optional space) after "@All"
          const regex = /(@All)\s?l\b/i;
          const match = blockText.match(regex);

          if (match) {
            blockText = blockText.replace(regex, "$1");

            const positionAfterMention = range.offset + match[1].length;

            // Update the selection to position after the cleaned mention
            updatedSelection = updatedSelection.merge({
              anchorOffset: positionAfterMention,
              focusOffset: positionAfterMention,
            });
          }
        }
      });

      return { ...block, text: blockText };
    });

    // Update the raw content with the cleaned blocks
    const cleanedRawContent = { ...rawContent, blocks: cleanedBlocks };
    const cleanedContentState = convertFromRaw(cleanedRawContent);

    // Create a new editor state with the cleaned content
    const updatedEditorState = EditorState.push(
      editorState,
      cleanedContentState,
      "change-block-data"
    );

    // Ensure the cursor moves to the correct position
    const editorStateWithSelection = EditorState.forceSelection(
      updatedEditorState,
      updatedSelection
    );

    this.setState({ editorState: editorStateWithSelection });
    this.props.editorStateValue(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };

  addUpdateData = () => {
    console.log("questionDescribed final", this.props.editorValue);
    this.props.textBoxStatusBack();
    const blocksFromHtml = htmlToDraft(this.props.editorValue);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    console.log(contentState);
    console.log(EditorState.createWithContent(contentState));
    this.setState({
      editorState: EditorState.createWithContent(contentState),
    });
  };

  _getLengthOfSelectedText = () => {
    const currentSelection = this.state.editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = this.state.editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength =
        startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      console.log(currentSelection);
      if (isStartAndEndBlockAreTheSame) {
        length +=
          currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }
          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  _handleBeforeInput = () => {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = this._getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > MAX_LENGTH - 1) {
      console.log("you can type max ten characters");

      return "handled";
    }
  };

  _handlePastedText = (pastedText) => {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText("").length;
    const selectedTextLength = this._getLengthOfSelectedText();

    if (
      currentContentLength + pastedText.length - selectedTextLength >
      MAX_LENGTH
    ) {
      console.log("you can type max ten characters");

      return "handled";
    }
  };
}

export default withTranslation()(TextEditorHomePost);
